import React, { Component } from "react";
import PropTypes from "prop-types";
import Link from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { FaRoute, FaMail, FaPhone } from "react-icons/fa";
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";

const Contact = () => {
  return (
    <Layout bodyClass=" " headerClass="fixed top-0 z-50 bg-white">
      <SEO title="Contact" />

      <div className="min-h-screen mb-6 flex flex-col items-start mt-20">
        <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-blue-500 mt-10">
          Contact
        </h2>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
        <div className="w-full">
          <div class="w-full md:w-2/3 p-6 flex flex-col flex-grow flex-shrink mx-auto mb-10">
            <div
              class="bg-white rounded-t overflow-hidden shadow h-contact-card bg-pack"
              style={{ backgroundImage: "url('/Contact.jpg')" }}
            ></div>
            <div class="bg-white  rounded-b overflow-hidden shadow">
              <div class="p-5">
                <p className="my-2">
                  <a href="tel:03.430.91.62">03.430.91.62</a>
                </p>
                <p className="my-2">
                  <a href="mailto:info@smart-fleet.be">info@smart-fleet.be</a>
                </p>
                <p className="my-2">
                  Pastorijveld 114,
                  <br />
                  2180 Ekeren
                  <a
                    className="ml-1 font-blue-500"
                    target="_blank"
                    title="Routebeschrijving"
                    href="https://www.google.be/maps/dir//Pastorijveld+114,+2030+Antwerpen/@51.2750948,4.4110268,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47c409cff8335165:0x391e31d0fcad4a03!2m2!1d4.4132208!2d51.2750915"
                  >
                    <FaRoute />
                  </a>
                </p>
                <p className="my-5 flex">
                  <a
                    className="mr-1"
                    href="https://www.facebook.com/SmartFleetbv/?ref=aymt_homepage_panel&eid=ARDcVAXW5JpoleLNfXM2LkrLnga7AIVkYIjUHO7ldl-JZe538z-B26hpi5jfuz3GlXUVV2vMzDMq5zlz"
                  >
                    <FaFacebookSquare />
                  </a>
                  <a
                    className="mr-1"
                    href="https://www.linkedin.com/company/smart-fleet-bv/?viewAsMember=true"
                  >
                    <FaLinkedin />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="relative footer-wave w-full -mb-20 bg-blue-100">
        <svg
          viewBox="0 0 1428 174"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g
              transform="translate(-2.000000, 44.000000)"
              fill="#FFFFFF"
              fill-rule="nonzero"
            >
              <path
                d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                opacity="0.100000001"
              ></path>
              <path
                d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                opacity="0.100000001"
              ></path>
              <path
                d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                id="Path-4"
                opacity="0.200000003"
              ></path>
            </g>
            <g
              transform="translate(-4.000000, 76.000000)"
              fill="#FFFFFF"
              fill-rule="nonzero"
            >
              <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
            </g>
          </g>
        </svg>
      </div>
    </Layout>
  );
};

export default Contact;
